import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DEFAULT_DATE_RANGE, DEFAULT_FILTER_OPTION } from 'constants/dates'
import { FilterOptionKey } from 'constants/dropdowns'
import { RangeKeyDict } from 'react-date-range'
import { fetchAdsCrmData } from 'store/services/adsCrmDataService'
import {
  ActivityAdsCrmData,
  FetchAdsCrmDataParams,
} from 'types/activityAdsCrmData.model'

interface AdsCrmDataState {
  dateRange: RangeKeyDict
  filterOption: FilterOptionKey
  adsCrmData: ActivityAdsCrmData[]
  isLoading: boolean
  error: string | null
  currentParams: Partial<FetchAdsCrmDataParams>
  source: string | null
  lastFetchedDateRange: RangeKeyDict | null
}

const initialState: AdsCrmDataState = {
  dateRange: DEFAULT_DATE_RANGE,
  filterOption: DEFAULT_FILTER_OPTION,
  adsCrmData: [],
  isLoading: false,
  error: null,
  currentParams: {},
  source: null,
  lastFetchedDateRange: null,
}

const adsCrmDataSlice = createSlice({
  name: 'adsCrmData',
  initialState,
  reducers: {
    reset: (state) => {
      state.adsCrmData = []
      state.error = null
    },
    setDateRange: (state, action: PayloadAction<RangeKeyDict>) => {
      state.dateRange = action.payload
    },
    setFilterOption: (state, action: PayloadAction<string>) => {
      state.filterOption = action.payload
    },
    setCurrentParams: (
      state,
      action: PayloadAction<Partial<FetchAdsCrmDataParams>>,
    ) => {
      state.currentParams = { ...state.currentParams, ...action.payload }
    },
    setSource: (state, action: PayloadAction<string | null>) => {
      state.source = action.payload
    },
    setLastFetchedDateRange: (state, action: PayloadAction<RangeKeyDict>) => {
      state.lastFetchedDateRange = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdsCrmData.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        fetchAdsCrmData.fulfilled,
        (state, action: PayloadAction<ActivityAdsCrmData[]>) => {
          state.isLoading = false
          state.adsCrmData = action.payload
          state.lastFetchedDateRange = state.dateRange
        },
      )
      .addCase(fetchAdsCrmData.rejected, (state, action) => {
        state.isLoading = false
        state.error = (action.payload as string) || 'An error occurred'
      })
  },
})

export const {
  setDateRange,
  setFilterOption,
  setCurrentParams,
  setSource,
  setLastFetchedDateRange,
  reset,
} = adsCrmDataSlice.actions

export default adsCrmDataSlice.reducer
